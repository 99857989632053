body {
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply bg-gray-50 dark:bg-gray-900 dark:text-stone-100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

input, textarea {
  @apply dark:bg-gray-800;
}

textarea:focus-visible, input:focus-visible {
  @apply outline-none ring-2 ring-gray-500;
}

.card {
  @apply rounded-lg shadow-card bg-white dark:bg-gray-800;
}

.card .card-title {
  @apply font-extrabold py-2 px-4 select-none border-gray-100 border-b dark:border-gray-900 dark:text-gray-100;
}

.card.no-rounded {
  @apply rounded-none;
}

.mastodon-html .h-card {
  @apply text-orange-700 hover:bg-orange-50 px-1 py-1 -mr-1 rounded transition dark:text-orange-400;
}

.mastodon-html .h-card:first-child {
  @apply -ml-1;
}

.mastodon-html .invisible {
  @apply hidden;
}

.mastodon-html a {
  @apply text-orange-600 hover:underline transition dark:text-orange-400;
}

.mastodon-html a .ellipsis:after {
  content: "…";
}

.mastodon-html .emoji {
  font-size: 0;
  vertical-align: middle;
  display: inline;
  @apply inline-block h-4 leading-8;
}

.mastodon-html .hashtag {
  padding: 2px 2px;
  @apply bg-orange-100 rounded border-orange-600 px-2 leading-8 dark:bg-orange-900;
}

.scroll-pane {
  @apply overflow-x-scroll overflow-y-hidden;
}

.scroll-pane {
  transition: scrollbar-color 0.2s;
  scrollbar-color: transparent transparent;
}

.scroll-pane:active, .scroll-pane:focus, .scroll-pane:hover {
  scrollbar-color: darkgray transparent;
}


.scroll-pane:active::-webkit-scrollbar-thumb,
.scroll-pane:focus::-webkit-scrollbar-thumb,
.scroll-pane:hover::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.scroll-pane::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: background-color 0.2s;
}

.scroll-pane::-webkit-scrollbar {
  width: .3em;
  height: .3em;
}
